import RegisterEmpresaComponent from '../components/RegisterEmpresa/RegisterEmpresa';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

export default function RegisterEmpresa() {
    return (
        <div>
                        <Navbar/>

            <RegisterEmpresaComponent/>
        </div>
    )
}