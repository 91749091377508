import RegisterSedeComponent from '../components/RegisterSede/RegisterSede';
import Navbar from '../components/Navbar/Navbar';

export default function RegisterSede() {
    return (
        <div>
                        <Navbar/>

            <RegisterSedeComponent/>
        </div>
    )
}