import TermsAndConditionsComponent from '../components/TermsAndConditions/TermsAndConditions'
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

export default function TermsAndConditions() {
    return (
        <div>
                       <Navbar/>
           
            <TermsAndConditionsComponent/>
            <Footer/>

        </div>
    )
}